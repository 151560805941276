<template>
  <input class="native-input sc-ion-input-md sc-ion-input-ios currencyInput" ref="inputRef" :value="formattedValue">
</template>

<script>
import { ref } from 'vue'
import useCurrencyInput from 'vue-currency-input'

export default {
  name: 'CurrencyInput',
  props: {
    modelValue: Number,
    currency: String,
    distractionFree: {type: Boolean, default: false},
    locale: {type: String, default: 'id-ID'}
    // additional props for options...
  },
  setup (props, { emit }) {
    const inputRef = ref(null)
    const { formattedValue } = useCurrencyInput({ inputRef, props, emit })
    // console.log(formattedValue)
    return { inputRef, formattedValue }
  }
}
</script>

<style scoped>
.currencyInput {
  text-align: right
}
</style>