
import { 
  IonButton,
  IonButtons,
  IonCard,
  IonCardContent,
  IonContent,
  IonDatetime,
  IonIcon,
  IonInput,
  IonHeader,
  IonItem,
  IonLabel,
  IonPage,
  IonSelect,
  IonSelectOption,
  IonTextarea,
  IonTitle,
  IonToolbar 
} from '@ionic/vue'
import { close, checkmarkOutline } from 'ionicons/icons'
import { defineComponent, reactive, computed } from 'vue'
import CurrencyInput from '@/components/CurrencyInput.vue'

export default defineComponent({
  name: 'ExpenseForm',
  components: { 
    IonButton,
    IonButtons,
    IonCard,
    IonCardContent,
    IonContent,
    IonDatetime,
    IonIcon,
    IonInput,
    IonHeader,
    IonItem,
    IonLabel,
    IonPage,
    IonSelect,
    IonSelectOption,
    IonTitle,
    IonTextarea,
    IonToolbar,
    CurrencyInput
  },
  // props: {
  //   formData: {
  //     type: Object,
  //     default: null
  //   },
  //   helo: {
  //     type: Object,
  //     default: null
  //   }
  // },
  props: { 
    formData: {
      type: Object,
      default: () => ({ 
        formType: 'AddExpenseItem',
        type: 'expense',
        formDetail: {
          id: null,
          category: null,
          description: null,
          transactionDate: null,
          nominal: 0
        }
      })
    }
  },
  setup (props: any, ctx) {
    const formItem = reactive<any>({
      visible: true,
      id: null,
      tempNominal: 0,
      nominal: 0,
      transactionDate: new Date().toISOString(),
      description: '',
      category: ''
    })

    const categoryLists: any = {
      income: [],
      expense: []
    }

    const putTitle = computed(() => {
      let result = ''
      switch (props.formData.formType) {
        case 'AddExpenseItem':
          result = 'Add Expense Item'
          break
        case 'ModifyExpenseItem':
          result = 'Modify Expense Item'
          Object.assign (formItem, {
            id: props.formData.formDetail.id,
            category: props.formData.formDetail.category,
            description: props.formData.formDetail.description,
            transactionDate: props.formData.formDetail.transactionDate.toDate().toISOString(),
            nominal: props.formData.formDetail.nominal,
            tempNominal: props.formData.formDetail.tempNominal 
          })
          // console.log(formItem)
          break
      }
      Object.assign(categoryLists, { ...props.formData.categoryLists})
      // console.log(categoryLists)
      return result
    })

    const submitForm = () => {
      // console.log('submit Expense Form')
      // console.log(formItem)
      const response = {
        'action': props.formData.formType,
        'formData': {
          'dataType': 'expense',
          'id': formItem.id,
          'category': formItem.category,
          'transactionDate': new Date(formItem.transactionDate),
          'description': formItem.description,
          'nominal': formItem.nominal
        }
      }
      if (props.formData.formType === 'AddExpenseItem') {
        delete response.formData.id
      }
      // console.log(response)
      ctx.emit('submit', response)
    }
    
    const closeForm = () => {
      // console.log('close Expense Form')
      const response = {
        'action': 'close',
        'formData': null
      }
      // console.log(response)
      ctx.emit('close', response)
    }

    return {
      //method
      submitForm,
      closeForm,

      //data
      formItem,
      categoryLists,

      //computed
      putTitle,

      //icon
      close,
      checkmarkOutline
    }
  }
})
