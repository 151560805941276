<template>
  <ion-page>
    <ion-header>
      <ion-toolbar>
        <ion-buttons slot="start">
          <ion-button color="secondary" @click="closeForm">
            <ion-icon slot="icon-only" ios="close" md="close"></ion-icon>
          </ion-button>
        </ion-buttons>
        <ion-title>{{ putTitle }}</ion-title>
      </ion-toolbar>
    </ion-header>
      
    <ion-content class="ion-padding">
      <ion-card>
        <ion-card-content>
          <ion-item v-if="formItem.itemId">
            <ion-label position="stacked">Item ID</ion-label>
            <ion-input
              name="id"
              v-model="formItem.id"
            ></ion-input>
          </ion-item>
          <ion-item>
            <ion-label position="stacked">Date</ion-label>
            <ion-datetime
              name="transactionDate"
              display-format="DDDD MMM D, YYYY"
              placeholder="Select Date"
              v-model="formItem.transactionDate"
            ></ion-datetime>
          </ion-item>
          <ion-item>
            <ion-label position="stacked">Category</ion-label>
            <ion-select 
              name="category"
              placeholder="Pick A Category"
              v-model="formItem.category"
            >
              <ion-select-option v-for="item in categoryLists.expense" :value="item" :key="item.index">{{item}}</ion-select-option>
            </ion-select>
          </ion-item>
          <ion-item>
            <ion-label position="stacked">Description</ion-label>
            <ion-textarea 
              name="description"
              rows="3"
              placeholder="Enter item description here..."
              v-model="formItem.description"
            ></ion-textarea>
          </ion-item>
          <ion-item>
            <ion-label position="stacked">Nominal</ion-label>
            <currency-input 
              v-model="formItem.nominal" 
              currency="IDR"
            />
          </ion-item>
          <ion-item hidden>
            <ion-label>Nominal</ion-label>
            <ion-input v-model="formItem.tempNominal" type="number"></ion-input>
          </ion-item>
          <ion-button slot="end" @click="submitForm" expand="block">
            <ion-icon slot="start" :icon="checkmarkOutline"></ion-icon> Submit
          </ion-button>
        </ion-card-content>
      </ion-card>
    </ion-content>
  </ion-page>
</template>

<script lang="ts">
import { 
  IonButton,
  IonButtons,
  IonCard,
  IonCardContent,
  IonContent,
  IonDatetime,
  IonIcon,
  IonInput,
  IonHeader,
  IonItem,
  IonLabel,
  IonPage,
  IonSelect,
  IonSelectOption,
  IonTextarea,
  IonTitle,
  IonToolbar 
} from '@ionic/vue'
import { close, checkmarkOutline } from 'ionicons/icons'
import { defineComponent, reactive, computed } from 'vue'
import CurrencyInput from '@/components/CurrencyInput.vue'

export default defineComponent({
  name: 'ExpenseForm',
  components: { 
    IonButton,
    IonButtons,
    IonCard,
    IonCardContent,
    IonContent,
    IonDatetime,
    IonIcon,
    IonInput,
    IonHeader,
    IonItem,
    IonLabel,
    IonPage,
    IonSelect,
    IonSelectOption,
    IonTitle,
    IonTextarea,
    IonToolbar,
    CurrencyInput
  },
  // props: {
  //   formData: {
  //     type: Object,
  //     default: null
  //   },
  //   helo: {
  //     type: Object,
  //     default: null
  //   }
  // },
  props: { 
    formData: {
      type: Object,
      default: () => ({ 
        formType: 'AddExpenseItem',
        type: 'expense',
        formDetail: {
          id: null,
          category: null,
          description: null,
          transactionDate: null,
          nominal: 0
        }
      })
    }
  },
  setup (props: any, ctx) {
    const formItem = reactive<any>({
      visible: true,
      id: null,
      tempNominal: 0,
      nominal: 0,
      transactionDate: new Date().toISOString(),
      description: '',
      category: ''
    })

    const categoryLists: any = {
      income: [],
      expense: []
    }

    const putTitle = computed(() => {
      let result = ''
      switch (props.formData.formType) {
        case 'AddExpenseItem':
          result = 'Add Expense Item'
          break
        case 'ModifyExpenseItem':
          result = 'Modify Expense Item'
          Object.assign (formItem, {
            id: props.formData.formDetail.id,
            category: props.formData.formDetail.category,
            description: props.formData.formDetail.description,
            transactionDate: props.formData.formDetail.transactionDate.toDate().toISOString(),
            nominal: props.formData.formDetail.nominal,
            tempNominal: props.formData.formDetail.tempNominal 
          })
          // console.log(formItem)
          break
      }
      Object.assign(categoryLists, { ...props.formData.categoryLists})
      // console.log(categoryLists)
      return result
    })

    const submitForm = () => {
      // console.log('submit Expense Form')
      // console.log(formItem)
      const response = {
        'action': props.formData.formType,
        'formData': {
          'dataType': 'expense',
          'id': formItem.id,
          'category': formItem.category,
          'transactionDate': new Date(formItem.transactionDate),
          'description': formItem.description,
          'nominal': formItem.nominal
        }
      }
      if (props.formData.formType === 'AddExpenseItem') {
        delete response.formData.id
      }
      // console.log(response)
      ctx.emit('submit', response)
    }
    
    const closeForm = () => {
      // console.log('close Expense Form')
      const response = {
        'action': 'close',
        'formData': null
      }
      // console.log(response)
      ctx.emit('close', response)
    }

    return {
      //method
      submitForm,
      closeForm,

      //data
      formItem,
      categoryLists,

      //computed
      putTitle,

      //icon
      close,
      checkmarkOutline
    }
  }
})
</script>