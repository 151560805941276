
import {
  IonButton,
  IonCard,
  IonCardContent,
  IonContent,
  IonFab,
  IonFabButton,
  IonFooter,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonModal,
  IonPage,
  IonRefresher,
  IonRefresherContent,
  IonToolbar,
  alertController
} from '@ionic/vue'
import { 
  defineComponent, 
  ref, 
  reactive, 
  computed, 
  onMounted,
  onUpdated,
  onBeforeUpdate
} from 'vue'
import { add, createOutline, trashOutline, chevronDownCircleOutline, filterOutline } from 'ionicons/icons'
import { useRouter } from 'vue-router'
import "firebase/firestore";
import AppHeader from '@/components/AppHeader.vue'
import ExpenseForm from '@/components/ExpenseForm.vue'
import AppFooter from '@/components/AppFooter.vue'
import ReportFilter from '@/components/ReportFilter.vue'
import useFirebaseFirestore from "../hooks/firebase-firestore"

export default defineComponent({
  name: 'ExpensesPage',
  components: {
    IonButton,
    IonCard,
    IonCardContent,
    IonContent,
    IonFab,
    IonFabButton,
    IonFooter,
    IonIcon,
    IonItem,
    IonLabel,
    IonList,
    IonModal,
    IonPage,
    IonRefresher,
    IonRefresherContent,
    IonToolbar,
    AppHeader,
    AppFooter,
    ExpenseForm,
    ReportFilter
  },
  setup() {
    const isAddExpenseOpenRef = ref(false)
    const setAddExpenseOpen = (state: any) => isAddExpenseOpenRef.value = state

    const isFilterOpenRef = ref(false)
    const setFilterOpen = (state: any) => isFilterOpenRef.value = state
    
    const expenseFormAction = { 
      formType: 'AddExpenseItem',
      type: 'expense',
      categoryLists: {},
      filterData: {
        fromDate: '',
        toDate: '',
        category: []
      },
      formDetail: {
        id: null,
        category: null,
        description: null,
        transactionDate: null,
        nominal: 0
      }
    }

    const expenseState = reactive ({
      selectedBookId: null
    })

    const selectedBookId = async () => {
      // const selectedBook: any = await localStorage.getItem('selectedBook')
      // console.log(JSON.parse(selectedBook).id)
      // reportState.selectedBookId = JSON.parse(selectedBook).id
      const selectedBook: any = await useFirebaseFirestore().selectedBookId
      expenseState.selectedBookId = selectedBook
      return expenseState.selectedBookId
    }
    const collectionType = "expense"
    let expenseFormKey = 1

    const sortOptions = {
      by: "createdAt",
      rule: "desc"
    }

    const today = new Date();
    const filterOptions = {
      fromDate: new Date(today.getFullYear(), today.getMonth(), 1),
      toDate: new Date(today.getFullYear(), today.getMonth() + 1, 0),
      category: []
    }

    const categoryLists = async () => {
      const localSelectedBooks: any = await localStorage.getItem('selectedBook')
      const selectedBooks = JSON.parse(localSelectedBooks)
      expenseFormAction.categoryLists = selectedBooks.settings.categories
      // filterOptions.category = selectedBooks.settings.categories.expense
      // console.log(incomeFormAction.formDetail.categoryLists)
    }
    
    const expenseData = reactive <Map<string, any>>(new Map<string, any>())

    const displayExpenseData = computed(() => Array.from(expenseData.values()))
    
    const finalBalance = computed (() => {
      const expenseNominal = displayExpenseData.value.map( (item) => item.nominal)
      const totalExpense = expenseNominal.reduce( (accumulator, item) => {
        return accumulator + item;
      }, 0)
      return totalExpense
    })

    const displayDate = (transactionDate: any) =>  transactionDate.toDate().toDateString()
    const displayNominals = (nominal: number) => new Intl.NumberFormat('id-ID', { style: 'currency', currency: 'IDR' }).format(nominal)

    const getExpenseData = async () => {
      expenseData.clear()
      const queryExpenseData = await useFirebaseFirestore()
          .getItem(collectionType, sortOptions, filterOptions);
      if (queryExpenseData.status == 1) {
        queryExpenseData.data.forEach( (doc: any) => {
          const itemId = doc.id
          expenseData.set(itemId, {
            ...doc.data(),
            id: itemId,
          })
        })
      }
      // console.log(expenseData)
    }

    const submitExpenseForm = async (event: any) => {
      // console.log(event)
      if (event.action === "AddExpenseItem") {
        const docId = String(new Date().getTime())
        await useFirebaseFirestore()
          .addNewItem(
            collectionType,
            docId,
            {...event.formData}
          )
        // console.log(addItem)
        // expenseData.set(addItem.id, {
        //   ...event.formData,
        //   addItem.id
        // })
        
      } else if (event.action === "ModifyExpenseItem") {
        // expenseData.set(event.formData.id, {
        //   ...event.formData
        // })
        await useFirebaseFirestore()
          .modifyItem(
            collectionType,
            event.formData.id, 
            {
              ...event.formData
            }
          )
      }
      setAddExpenseOpen(false)
      await getExpenseData()
      localStorage.setItem('needUpdateReportList', "1")
      expenseFormKey++
      Object.assign (expenseFormAction, {
        formType: 'AddExpenseItem',
        formDetail: {
          id: null,
          category: null,
          description: null,
          transactionDate: null,
          nominal: 0
        }
      })
      // console.log(displayExpenseData)
    }
    
    const closeExpenseForm = (event: any) => {
      // console.log(event)
      event.action == 'close' ? setAddExpenseOpen(false) : null
      Object.assign (expenseFormAction, {
        formType: 'AddExpenseItem',
        formDetail: {
          id: null,
          category: null,
          description: null,
          transactionDate: null,
          nominal: 0
        }
      })
    }

    const deleteExpenseItem = async (item: any) => {
      const alert = await alertController
        .create({
          cssClass: 'my-custom-class',
          header: 'Confirm!',
          message: 'Delete Item ' + item.description + ' !!!',
          buttons: [
            {
              text: 'Cancel',
              role: 'cancel',
              cssClass: 'secondary',
              handler: () => {
                // console.log('Confirm Cancel:', blah)
              },
            },
            {
              text: 'Okay',
              handler: async () => {
                expenseFormKey++
                expenseData.delete(item.id)
                await useFirebaseFirestore()
                  .deleteItem(
                    collectionType, 
                    item.id
                  )
                localStorage.setItem('needUpdateReportList', "1")
                // await getExpenseData()
                // console.log('Confirm Okay')
              },
            },
          ],
        });
      return alert.present();
    }

    const modifyExpenseItem = (item: any) => {
      // console.log(item)
      Object.assign (expenseFormAction, {
        formType: 'ModifyExpenseItem',
        formDetail: {
          id: item.id,
          category: item.category,
          description: item.description,
          transactionDate: item.transactionDate,
          nominal: item.nominal
        }
      })
      expenseFormKey++
      // console.log('Expense Form Key' + expenseFormKey)
      setAddExpenseOpen(true)
    }

    const actionExpenseItem = async (item: any) => {
      const alert = await alertController
        .create({
          cssClass: 'my-custom-class',
          header: 'Confirm!',
          message: 'Modify Item ' + item.description + ' !!!',
          buttons: [
            {
              text: 'Cancel',
              role: 'cancel',
              cssClass: 'secondary',
              handler: () => {
                // console.log('Confirm Cancel:', blah)
              },
            },
            {
              text: 'Delete',
              handler: () => {
                deleteExpenseItem(item)
              },
            },
            {
              text: 'Modify',
              handler: () => {
                modifyExpenseItem(item)
              },
            },
          ],
        });
      return alert.present();
    }

    const checkBookId = async () => {
      // console.log('Last BookID: ' + expenseState.selectedBookId)
      const currentBook: any = await localStorage.getItem('selectedBook')
      const currentBookId = JSON.parse(currentBook).id
      // console.log('current BookID: ' + currentBookId)
      if (currentBookId !== expenseState.selectedBookId) {
        // console.log('Selected Book ID different')
        // console.log('New BookId: ' + expenseState.selectedBookId)
        // console.log('Need Update Expense Data')
        expenseState.selectedBookId = currentBookId
        await getExpenseData()
        // return {status: 1, message: 'Need Update'}
      } else {
        // console.log('Selected Book ID same')
        // console.log('No Need Update Data')
        // return {status: 0, message: 'No Need Update'}
      }
    }

    const doRefresh = (event: any) => {
      // console.log('Begin async operation');

      setTimeout(async () => {
        // console.log('Async operation has ended');
        await getExpenseData()
        event.target.complete();
      }, 5000);
    }

    const filterData = async (event: any) => {
      // console.log(event.action)
      if (event.action == 'filter') {
        filterOptions.fromDate = event.formData.filterFromDate
        filterOptions.toDate = event.formData.filterToDate
        filterOptions.category = event.formData.filterCategory
        expenseFormAction.filterData.fromDate = event.formData.filterFromDate
        expenseFormAction.filterData.toDate = event.formData.filterToDate
        expenseFormAction.filterData.category = event.formData.filterCategory
        setFilterOpen(false)
        await getExpenseData()
      }
    }

    const closeFilterData = (event: any) => {
      // console.log(event)
      event.action == 'close' ? setFilterOpen(false) : null
    }


    onMounted( async () => {
      await selectedBookId()
      await categoryLists()
      await getExpenseData()
    })

    onBeforeUpdate ( () => {
      // console.log('on Befreupdate')
      setFilterOpen(false)
      setAddExpenseOpen(false)
    })

    onUpdated( async () => {
      // console.log('updated!')
      // const checkBookId = await useGlobalHelpers()
      //       .checkBookId(state.selectedBookId)
      // console.log(checkBookId)
      // if (checkBookId.status == 1) {
      //   await getExpenseData()
      // }
      await checkBookId()
      await categoryLists()
      // await getExpenseData()
    })

    return {
      //function
      ...expenseState,
      router: useRouter(),
      isAddExpenseOpenRef,
      setAddExpenseOpen,
      isFilterOpenRef,
      setFilterOpen,
      finalBalance,
      expenseFormAction,
      submitExpenseForm,
      closeExpenseForm,
      actionExpenseItem,
      deleteExpenseItem,
      modifyExpenseItem,
      expenseFormKey,
      displayExpenseData,
      getExpenseData,
      selectedBookId,
      checkBookId,
      doRefresh,
      filterData,
      closeFilterData,
      
      //properties
      displayDate,
      displayNominals,
      sortOptions,

      //icon
      add,
      createOutline,
      trashOutline,
      chevronDownCircleOutline,
      filterOutline
    }
  }
})
